import BaseApi from '../../infraestructure/BaseApi'

export default class SaleApi extends BaseApi {
    constructor(client, endPoint) {
        super(client, endPoint)
    }

    doBill(id){
        return this.client.get(`${this.endPoint}/do-bill/${id}`)
    }

    doBillCancellation(id){
        return this.client.get(`${this.endPoint}/do-bill-cancellation/${id}`)
    }

    all(requests){
        return this.all(requests)
    }

    updateRestructureLocations(){
        return this.client.put(`${this.endPoint}/restructure-locations`, {})
    }
}