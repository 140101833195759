import BaseModule from '../infraestructure/BaseModule'
import SaleViewModel from '../../core/features/sales/SaleViewModel'

export default {
    extends: BaseModule,
    data() {
        return {
            viewModel: null
        }
    },
    created() {
        this.viewModel = new SaleViewModel(this) 
    },
    methods: {
        onActive() {
            
        }
    }
}