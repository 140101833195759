import _ from "lodash";
import moment from "moment";
import BaseViewModel from "../../infraestructure/BaseViewModel";
import CustomerReception from "./CustomerReception";
import ReceptionPackage from "./CustomerReceptionPackage";
import ReceptionCustomer from "./ReceptionCustomer";
import Customer from "../customers/Customer";
import CustomerLocation from "../customerLocation/CustomerLocation";
import ServiceCost from "../serviceCosts/ServiceCost";
import Destination from "../destinations/Destination";
import { MXN } from "../../helpers/MoneyHelper";
import ReceptionHelper from "../../helpers/ReceptionHelper";
import ProductTypesHelper from "../../helpers/ProductTypesHelper";
import Neighborhood from "../neighborhoods/Neighborhood";
import TaxRegime from "../taxRegimes/TaxRegime";
import PaymentMethod from "../paymentMethods/PaymentMethod";
import BillUsingType from "../billUsingTypes/BillUsingType";
import GpsTrackingHelper from "../../helpers/GpsTrackingHelper";

export default class CustomerReceptionsViewModel extends BaseViewModel {
  constructor(view) {
    super(view);
  }

  static SolvencyType = ReceptionHelper.SolvencyType;
  static receptionTypes = ReceptionHelper.receptionTypes;
  static receptionServiceTypes = ReceptionHelper.receptionServiceTypes;
  static paymentTypes = ReceptionHelper.paymentTypes;
  static receptionTrackingStatus = ReceptionHelper.receptionTrackingStatus;

  //#region MODULO CLIENTES

  create(data) {
    return new CustomerReception(data, "create");
  }

  edit(data, customerId) {
    let item = new CustomerReception(data, "update");
    item.senderLocation = item.senderLocation
      ? new CustomerLocation(item.senderLocation, "update")
      : null;
    item.receiverLocation = item.receiverLocation
      ? new CustomerLocation(item.receiverLocation, "update")
      : null;
    item.customerId = customerId;
    return item;
  }

  show(data, customerId) {
    let item = new CustomerReception(data, "show");
    item.senderLocation = item.senderLocation
      ? new CustomerLocation(item.senderLocation, "show")
      : null;
    item.receiverLocation = item.receiverLocation
      ? new CustomerLocation(item.receiverLocation, "show")
      : null;
    item.customerId = customerId;
    return item;
  }

  save(data) {
    if (data.state === "create") {
      this.api.customerReceptions
        .create(data.customerId, data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onSaveError);
    } else {
      this.api.customerReceptions
        .update(data.customerId, data.id, data.toSend())
        .then((response) => this.view.onSaveResponse(response.data))
        .catch(this.view.onSaveError);
    }
  }

  delete(customerId, id) {
    this.api.customerReceptions
      .delete(customerId, id)
      .then((response) =>
        this.view.onDeleteCustomerReceptionResponse(response.data)
      )
      .catch(this.view.onError);
  }

  findCollection(customerId, filters) {
    this.api.customerReceptions
      .find(customerId, this.getQueryParameters(filters))
      .then((response) =>
        this.view.onSearchResponse(response.data, response.headers)
      )
      .catch(this.view.onError);
  }

  findItem(customerId, id) {
    this.api.customerReceptions
      .findOne(customerId, id)
      .then((response) =>
        this.view.onFindItemResponseCustomerReception(response.data, customerId)
      )
      .catch(this.view.onError);
  }

  findReceptionOrderDetail(customerId, id) {
    this.api.customerReceptions
      .findReceptionOrderDetail(customerId, id)
      .then((response) =>
        this.view.onFindReceptionOrderDetailResponse(response.data, customerId)
      )
      .catch(this.view.onError);
  }

  async bindFormAsync(formData, currentSession) {
    try {
      if (formData.state === "create" || formData.state === "update") {
        // obtiene la información de los costos de servicios
        const serviceCostResponse = await this.api.serviceCosts.find(
          `Skip=0&limit=100000&IsActive=true`
        );
        this.view.serviceCosts = this.mapServiceCosts(
          serviceCostResponse.data.data
        );

        if (formData.orderFiscal.postalCode) {
          this.view.fiscalPostalCodes = [formData.orderFiscal.postalCode];
        }

        const taxRegimesResponse = await this.api.taxRegimes.find(
          `Skip=0&Limit=1000&IsActive=true`
        );
        this.view.taxRegimes = this.mapTaxRegimes(taxRegimesResponse.data.data);

        const billUsingTypeResponse = await this.api.billUsingTypes.find(
          `Skip=0&Limit=1000&IsActive=true`
        );
        this.view.billUsingTypes = this.mapBillUsingTypes(
          billUsingTypeResponse.data.data
        );

        const paymentMethodsResponse = await this.api.paymentMethods.find(
          `Skip=0&Limit=1000&IsActive=true`
        );
        this.view.paymentMethods = this.mapPaymentMethods(
          paymentMethodsResponse.data.data
        );

        // obtiene la información de los contactos por default
        if (formData.sender.customer) {
          if (formData.sender.type === "contacts") {
            const customerLocationsResponse = await this.api.customerLocation.find(
              formData.sender.customer.id,
              `Skip=0&limit=100000&IsActive=true`
            );
            this.view.senderCustomerLocations = this.mapCustomerLocations(
              customerLocationsResponse.data.data
            );

            const senderContactsResponse = await this.api.customersContact.find(
              formData.customerId,
              `Skip=0&Limit=10000`
            );
            this.view.senderContacts = senderContactsResponse.data.data;
            //this.view.receiverContacts.push(formData.customer)
          } else {
            const customerLocationsResponse = await this.api.customerLocation.find(
              formData.customerId,
              `Skip=0&limit=100000&IsActive=true`
            );
            this.view.senderCustomerLocations = this.mapCustomerLocations(
              customerLocationsResponse.data.data
            );
          }
        }

        /* const customerContactsResponse = await this.api.customersContact.find(
    formData.customerId,
    `Skip=0&Limit=10000`
  );
  this.view.onFindReceiverCustomerContactsResponse(
    customerContactsResponse.data
  ); */

        if (formData.receiver.customer) {
          if (formData.receiver.type === "contacts") {
            const receiverLocationsResponse = await this.api.customerLocation.find(
              formData.receiver.customer.id,
              `Skip=0&limit=100000&IsActive=true`
            );

            this.view.receiverCustomerLocations = this.mapCustomerLocations(
              receiverLocationsResponse.data.data
            );

            const receiverContactsResponse = await this.api.customersContact.find(
              formData.customerId,
              `Skip=0&Limit=10000`
            );
            this.view.receiverContacts = receiverContactsResponse.data.data;
            const exists = _.find(
              this.view.receiverContacts,
              (item) => item.id === formData.receiver.customer.id
            );
            if (!exists)
              this.view.receiverContacts.push(formData.receiver.customer);
          } else {
            const receiverLocationsResponse = await this.api.customerLocation.find(
              formData.customerId,
              `Skip=0&limit=100000&IsActive=true`
            );

            this.view.receiverCustomerLocations = this.mapCustomerLocations(
              receiverLocationsResponse.data.data
            );
          }
        }

        formData.traces = this.mapReceptionPackages(formData.traces);

        // asigna los valores de default del cliente
        this.bindCurrentSession(currentSession);
      }
      formData.isDelivered = formData.deliveryLat && formData.deliveryLng;
    } catch (error) {
      this.view.onError(error);
    }
  }

  bindCurrentSession(currentSession) {
    if (currentSession) {
      this.view.$set(
        this.view.formData.sender,
        "contactName",
        currentSession.contactName
      );
      this.view.$set(
        this.view.formData.sender,
        "contactEmail",
        currentSession.contactEmail
      );
      this.view.$set(
        this.view.formData.sender,
        "contactPhone",
        currentSession.contactPhone
      );
    }
  }

  import(file) {
    this.api.customerReception
      .import(file)
      .then((response) => this.view.onImportResponse(response.data))
      .catch(this.view.onError);
  }

  mapCollection(collection) {
    return collection.map((item) => new CustomerReception(item));
  }

  mapCustomerLocations(collection) {
    return collection.map((item) => new CustomerLocation(item));
  }

  mapReceptionPackages(collection) {
    return collection.map((item) => new ReceptionPackage(item));
  }

  mapTaxRegimes(collection) {
    return collection.map((item) => new TaxRegime(item));
  }

  mapBillUsingTypes(collection) {
    return collection.map((item) => new BillUsingType(item));
  }

  mapPaymentMethods(collection) {
    return collection.map((item) => new PaymentMethod(item));
  }

  //#endregion

  //#Find Locations

  findCustomerReceptionLocation(criteria) {
    this.api.customerReceptions
      .findCustomerReceptionLocation(criteria)
      .then((response) =>
        this.view.onFindCustomerReceptionLocation(response.data)
      )
      .catch(this.view.onError);
  }

  findLocations(customerId, criteria, responseCallback) {
    this.api.customerLocation
      .find(customerId, criteria)
      .then((response) => responseCallback(response.data))
      .catch(this.view.onError);
  }

  findLocation(customerId, id, responseCallback) {
    this.api.customerLocation
      .findOne(customerId, id)
      .then((response) => responseCallback(response.data))
      .catch(this.view.onError);
  }

  //#End Find Location

  //#Find solvencies
  findCustomerSolvency() {
    this.api.solvencies
      .findAvailableSolvencyByCustomerAccount()
      .then((response) =>
        this.view.onFindCustomerSolvencyResponse(response.data)
      )
      .catch(this.view.onError);
  }

  //#End Find solvencies

  //#add functions of the Receptionsviewmodel

  //#endregion

  //#region QUERIES

  findOrigins(criteria) {
    this.api.destinations
      .find(criteria)
      .then((response) => this.view.onFindOriginsResponse(response.data))
      .catch(this.view.onError);
  }

  findDestinations(criteria) {
    this.api.destinations
      .find(criteria)
      .then((response) => this.view.onFindDestinationsResponse(response.data))
      .catch(this.view.onError);
  }

  findCustomers(criteria) {
    this.api.customers
      .find(criteria)
      .then((response) => this.view.onFindCustomerResponse(response.data))
      .catch(this.view.onError);
  }

  findProductypesFilterTree() {
    this.api.productypes
      .findFilterTree()
      .then((response) => this.view.onFindItemFilterTreeResponse(response.data))
      .catch(this.view.onError);
  }

  //#endregion QUERIES

  //#region Neighborhoods

  findNeighborhoods(criteria) {
    this.api.neighborhoods
      .find(criteria)
      .then((response) => this.view.onFindNeighborhoodsResponse(response.data))
      .catch(this.view.onError);
  }

  addNeighborhood(data) {
    return new Neighborhood(data);
  }

  createNeighborhood(data) {
    this.api.neighborhoods
      .create(data.toSend())
      .then((response) => {
        return this.api.neighborhoods.findOne(response.data.id);
      })
      .then((response) => this.view.onCreateNeighborhoodResponse(response.data))
      .catch(this.view.onError);
  }

  //#endregion Neighborhoods

  //#region RECEPTION CUSTOMER

  findCustomer(id, type) {
    this.api.customers
      .findOne(id)
      .then((response) => {
        if (type === 1) {
          this.view.onCustomerSenderResponse(response.data);
        } else {
          this.view.onCustomerReceiverResponse(response.data);
        }
      })
      .catch(this.view.onError);
  }

  createReceptionCustomer(data) {
    return new ReceptionCustomer(data, "create");
  }

  bindFormReceptionCustomer() { }

  //#endregion CUSTOMER

  //#region RECEPTION PACKAGE

  createReceptionPackage(data) {
    return new ReceptionPackage(data, "create");
  }

  editReceptionPackage(data) {
    return new ReceptionPackage(data.toSend(), "update");
  }

  bindReceptionPackageForm() { }

  deleteReceptionPackage(collection, index) {
    collection.splice(index, 1);
  }

  upsertReceptionPackage(collection, data, selectedIndex) {
    if (data.state === "update") {
      this.view.$set(collection, selectedIndex, data);
    } else {
      collection.push(data);
    }
    selectedIndex = null;
  }

  /**
        Metodo para calcular el costo total.
  
     */
  calculateTotalPrice(collection) {
    return _.sum(collection, (item) => {
      return item.subtotal;
    });
  }

  calculateDeliveryPrice = (
    receptionPackage,
    deliveryPriceCost,
    weightCostConfig
  ) =>
    ReceptionHelper.calculateDeliveryPrice(
      receptionPackage,
      deliveryPriceCost,
      weightCostConfig
    );

  calculateVolumetricWeight = (quantity, legth, height, width) =>
    ReceptionHelper.calculateVolumetricWeight(quantity, legth, height, width);

  //#endregion

  findSenderCustomers(criteria) {
    this.api.customers
      .find(criteria)
      .then((response) =>
        this.view.onFindSenderCustomersResponse(response.data)
      )
      .catch(this.view.onError);
  }

  findSenderCustomerLocations(id) {
    this.api.customers
      .findOne(id)
      .then((response) =>
        this.view.onFindSenderCustomerLocationsResponse(response.data)
      )
      .catch(this.view.onError);
  }

  findReceiverCustomers(criteria) {
    this.api.customers
      .find(criteria)
      .then((response) =>
        this.view.onFindReceiverCustomersResponse(response.data)
      )
      .catch(this.view.onError);
  }

  findReceiverCustomerLocations(id) {
    this.api.customers
      .findOne(id)
      .then((response) =>
        this.view.onFindReceiverCustomerLocationsResponse(response.data)
      )
      .catch(this.view.onError);
  }

  findWeightCosts() {
    this.api.weightCosts
      .find()
      .then((response) => this.view.onFindWeightCostsResponse(response.data))
      .catch(this.view.onError);
  }

  findAvailableCustomerSolvency(data) {
    this.api.solvencies
      .findAvailableSolvencyByCustomerAccount(data)
      .then((response) =>
        this.view.onFindByAilableCustomerSolvencyResponse(response.data)
      )
      .catch(this.view.onError);
  }

  findProductTypes(criteria) {
    this.api.productypes
      .find(criteria)
      .then((response) => this.view.onFindProductTypesResponse(response.data))
      .catch(this.view.onError);
  }

  findProductType(id) {
    this.api.productypes
      .findOne(id)
      .then((response) => this.view.onFindProductTypeResponse(response.data))
      .catch(this.view.onError);
  }

  findCustomerContacts(customerId, criteria, responseCallback) {
    this.api.customersContact
      .find(customerId, criteria)
      .then((response) => responseCallback(response.data))
      .catch(this.view.onError);
  }

  requestPickingPdf(customerId, orderId) {
    this.api.customerReceptions
      .requestPickingPdf(customerId, orderId)
      .then((response) => this.view.onRequestPickingPdfResponse(response.data))
      .catch(this.view.onRequestPickingPdfError);
  }

  mapCustomers(collection) {
    return collection.map((item) => new Customer(item));
  }

  /*   mapCustomerLocations(collection) {
          return collection.map((item) => new CustomerLocation(item))
      } */

  mapServiceCosts(collection) {
    return collection.map((item) => new ServiceCost(item));
  }

  mapReceptionData(data) {
    let item = new CustomerReception(data, "update");
    item.senderLocation = new CustomerLocation(item.senderLocation, "update");
    item.receiverLocation = new CustomerLocation(
      item.receiverLocation,
      "update"
    );
    return item;
  }

  mapFilterCustomers(collection) {
    return collection.join(",");
  }

  mapFilterSenderCustomers(collection) {
    return collection.join(",");
  }

  mapPlaces(collection) {
    return collection.map((item) => new Destination(item));
  }

  formatDateRangeDate(dateRange) {
    return {
      startDate: moment(dateRange[0]).format("YYYY-MM-DD"),
      endDate: moment(dateRange[1]).format("YYYY-MM-DD"),
    };
  }

  //#region VERIFICATION SECTION

  isStepValid(formData, stepActive) {
    var isValid = false;
    switch (stepActive) {
      case 0:
        if (!this.isReceptionSeviceTypeSelected(formData))
          throw new Error("Tipo de Servicio requerido");
        if (!this.isSenderAdded(formData))
          throw new Error("Datos de Remitente requerido");
        if (!this.isReceiverAdded(formData))
          throw new Error("Datos de Destinatario requerido");
        isValid = true;
        break;
      case 1:
        if (!this.isReceptionSeviceTypeSelected(formData))
          throw new Error("Tipo de Servicio requerido");
        if (!this.isSolvencyPrepaid(formData)) {
          if (!this.isServiceCostSelected(formData))
            throw new Error("Flete requerido");
        } else {
          this.view.$refs.formDataStep1.validateField("originPlace");
          this.view.$refs.formDataStep1.validateField("destinationPlace");
          if (!this.isOriginAndDestinationPlaceSelected(formData))
            throw new Error("Lugar de Origen y/o Destino requerido");
        }
        if (!this.hasPackages(formData))
          throw new Error("Al menos 1 paquete requerido.");
        isValid = true;
        break;
      default:
        break;
    }
    return isValid;
  }

  isReceptionSeviceTypeSelected(formData) {
    return !_.isNil(formData.receptionServiceType);
  }

  isServiceCostSelected(formData) {
    return !_.isNil(formData.serviceCost) && !_.isEmpty(formData.serviceCost);
  }

  isSolvencyPrepaid(formData) {
    return (
      formData.solvencyType &&
      formData.solvencyType === CustomerReceptionsViewModel.SolvencyType.Prepaid
    );
  }

  isPrepaid(receptionServiceType) {
    return (
      receptionServiceType &&
      receptionServiceType === ReceptionHelper.CONSTANT_SOLVENCY_TYPES.PREPAID
    );
  }

  isOriginAndDestinationPlaceSelected(formData) {
    return formData.originPlace && formData.destinationPlace;
  }

  hasPackages(formData) {
    return (
      !_.isNil(formData.receptionPackages) &&
      !_.isEmpty(formData.receptionPackages)
    );
  }

  isReceptionServiceTypeSelected(formData) {
    return !_.isNil(formData.receptionServiceType);
  }

  isSenderAdded(formData) {
    return (
      !_.isNil(formData.senderName) &&
      !_.isEmpty(formData.senderName) &&
      !_.isNil(formData.senderPhone) &&
      !_.isEmpty(formData.senderPhone) &&
      !_.isNil(formData.senderObservation) &&
      !_.isEmpty(formData.senderObservation)
    );
  }

  isReceiverAdded(formData) {
    return (
      !_.isNil(formData.receiverName) &&
      !_.isEmpty(formData.receiverName) &&
      !_.isNil(formData.receiverPhone) &&
      !_.isEmpty(formData.receiverPhone) &&
      !_.isNil(formData.receiverObservation) &&
      !_.isEmpty(formData.receiverObservation)
    );
  }

  isPaymentTypeSelected(formData) {
    return (
      !_.isNil(formData.paymentType) &&
      (_.isNumber(formData.paymentType) || !_.isEmpty(formData.paymentType))
    );
  }

  isWidthValid(value, callback) {
    if (!value) {
      callback(new Error("Ancho requerido"));
    } else if (!_.isNumber(value)) {
      callback(new Error("Valores numericos requerido"));
    } else {
      if (Number(value) < 0) callback(new Error("Valor mayor requerido"));

      callback();
    }
  }

  isHeightValid(value, callback) {
    if (!value) {
      callback(new Error("Alto requerido"));
    } else if (!_.isNumber(value)) {
      callback(new Error("Valores numericos requerido"));
    } else {
      if (Number(value) < 0) callback(new Error("Valor mayor requerido"));

      callback();
    }
  }

  isLengthValid(value, callback) {
    if (!value) {
      callback(new Error("Longitud requerido"));
    } else if (!_.isNumber(value)) {
      callback(new Error("Valores numericos requerido"));
    } else {
      if (Number(value) < 0) callback(new Error("Valor mayor requerido"));

      callback();
    }
  }

  isQuantityValid(value, callback) {
    if (!value) {
      callback(new Error("Cantidad requerido"));
    } else if (!_.isNumber(value)) {
      callback(new Error("Valores numericos requerido"));
    } else {
      if (Number(value) < 0) callback(new Error("Valor mayor requerido"));
      callback();
    }
  }

  isWeightValid(value, callback) {
    if (!value) {
      callback(new Error("Peso requerido"));
    } else if (!_.isNumber(value)) {
      callback(new Error("Valores numericos requerido"));
    } else {
      if (Number(value) < 0) callback(new Error("Valor mayor requerido"));

      callback();
    }
  }

  isProductTypesTreeEmpty = (data) =>
    ProductTypesHelper.isProductTypesTreeEmpty(data);

  receiverSizeValidation(rules, value, callback) {
    if (value && _.size(value) > 200) {
      callback(
        new Error(
          "Observación o referencia de destinatario 200 caracteres máximo"
        )
      );
    } else {
      callback();
    }
  }
  senderSizeValidation(rules, value, callback) {
    if (value && _.size(value) > 200) {
      callback(
        new Error("Observación o referencia de remitente 200 caracteres máximo")
      );
    } else {
      callback();
    }
  }

  descriptionSizeValidation(rules, value, callback) {
    if (value && _.size(value) > 100) {
      callback(new Error("Descripción 100 caracteres máximo"));
    } else {
      callback();
    }
  }

  orderFiscalBillUsingTypeValidation(rules, value, callback) {
    if (!value) {
      callback(new Error("Uso de CFDI requerido"));
    } else {
      callback();
    }
  }

  orderFiscalPaymentMethodValidation(rules, value, callback) {
    if (!value) {
      callback(new Error("Metodo de Pago requerido"));
    } else {
      callback();
    }
  }

  //#endregion

  printTrackingDocument(receptionId) {
    this.api.receptions
      .printTrackingDocument(receptionId)
      .then((response) =>
        this.view.onPrintTrackingDocumentResponse(response.data)
      )
      .catch(this.view.onError);
  }

  printTrackingLabels(receptionId) {
    this.api.receptions
      .printTrackingLabels(receptionId)
      .then((response) =>
        this.view.onPrintTrackingLabelsResponse(response.data)
      )
      .catch(this.view.onError);
  }

  formatMoney = (value) => `${MXN(value).format()} MXN`;

  calculatePackages(receptionPackages, serviceDeliveryPrice) {
    return receptionPackages.map((receptionPackage) => {
      receptionPackage.subtotal = this.calculateDeliveryPrice(
        receptionPackage,
        serviceDeliveryPrice
      );
      return receptionPackage;
    });
  }

  calculateSummary(param) {
    const { columns, data } = param;
    const sums = [];
    columns.forEach((column, index) => {
      if (index === 0) {
        sums[index] = "Total";
        return;
      }
      const values = data.map((item) => Number(item[column.property]));
      if (!values.every((value) => isNaN(value))) {
        if (column.label === "Costo") {
          var total = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = this.formatMoney(total);
        } else {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }
      } else {
        sums[index] = "";
      }
    });

    return sums;
  }

  getNameReceptionType = (type) => ReceptionHelper.getNameReceptionType(type);

  getNamePaymentType = (type) => ReceptionHelper.getNamePaymentType(type);

  getNameClientType = (type) => ReceptionHelper.getNameClientType(type);

  getIconTrackingStatus = (status) =>
    ReceptionHelper.getIconTrackingStatus(status);

  getStyleTrackingStatus = (status) =>
    ReceptionHelper.getStyleTrackingStatus(status);

  getNameTrackingStatus = (status) =>
    ReceptionHelper.getNameTrackingStatus(status);

  getSelectedProductType = (collection) =>
    ProductTypesHelper.getSelectedProductType(collection);

  getServiceDeliveryPrice(formData, serviceCostAmount) {
    return this.isPrepaid(formData.receptionServiceType)
      ? formData.serviceDeliveryPrice
      : serviceCostAmount;
  }

  filterReceptionServiceTypes(collection) {
    return collection.filter(
      (item) => item.id !== ReceptionHelper.CONSTANT_SOLVENCY_TYPES.CASH
    );
  }

  formatServiceCost(serviceCost, receptionServiceType, serviceDeliveryPrice) {
    let resultAmount = this.isPrepaid(receptionServiceType)
      ? serviceDeliveryPrice
      : serviceCost.amount;
    return serviceCost.getFullDescriptionNoPrice(resultAmount);
  }

  findOrderTrackingDocument(orderNumber) {
    this.api.orders
      .findPickingOrder(orderNumber)
      .then((response) =>
        this.view.onGenerateTrackingDocumentResponse(response.data)
      )
      .catch(this.view.onGenerateTrackingDocumentError);
  }

  async updateFiscalInformation(option, formData) {
    try {
      let informationResult = null;

      this.view.setFiscalData({
        id: null,
        fiscalCode: "",
        fiscalName: "",
        fiscalEmail: "",
        postalCode: null,
      });

      switch (option) {
        case "sender":
          if (formData.sender.type === "account") {
            informationResult = await this.findFiscalCustomerAsync(
              this.view.$store.getters["admin/getUserActive"].email
            );
          } else if (formData.sender.customer) {
            // currentEmail = formData.sender.customer.contactEmail
            informationResult = await this.findCustomerContactAsync(
              this.view.$store.getters["admin/getUserActive"].email,
              formData.sender.customer.id
            );
          } else {
            throw new Error("Contacto de remitente no seleccionado.");
          }
          break;
        case "receiver":
          if (formData.receiver.type === "account") {
            informationResult = await this.findFiscalCustomerAsync(
              this.view.$store.getters["admin/getUserActive"].email
            );
          } else if (formData.receiver.customer) {
            informationResult = await this.findCustomerContactAsync(
              this.view.$store.getters["admin/getUserActive"].email,
              formData.receiver.customer.id
            );
          } else {
            throw new Error("Contacto de destinatario no seleccionado.");
          }
          break;
        case "my-account":
          informationResult = await this.findFiscalCustomerAsync(
            this.view.$store.getters["admin/getUserActive"].email
          );
          break;
        default:
          informationResult = await this.findFiscalGeneralPublic();
          break;
      }

      if (informationResult.fiscalRegime) {
        const fiscalRegimeResponse = await this.api.taxRegimes.findOne(informationResult.fiscalRegime.id)
        this.view.billUsingTypes = this.mapBillUsingTypes(fiscalRegimeResponse.data.data.billUsingTypes)
      }

      return informationResult;
    } catch (error) {
      this.view.notifyWarning(error.message);
    }
  }

  async findFiscalGeneralPublic() {
    try {
      // Obtiene el cliente con datos de publico en general para la factura
      const defaultCustomer = await this.api.customers.find(
        `FullSearch=publico en general`
      );

      if (
        _.isNil(defaultCustomer.data.data) ||
        _.isEmpty(defaultCustomer.data.data[0])
      ) {
        this.view.config.isVisible = false;
        throw new Error("Configuración de publico en general no existente");
      }

      const response = await this.api.customers.findOne(
        defaultCustomer.data.data[0].id
      );

      return {
        id: response.data.data.id,
        fiscalName: response.data.data.fiscalName,
        fiscalCode: response.data.data.fiscalCode,
        fiscalEmail: response.data.data.contactEmail,
        postalCode: response.data.data.fiscalPostalCode,
        fiscalRegime: response.data.data.taxRegime,
      };
    } catch (error) {
      this.view.onError(error);
    }
  }

  async findFiscalCustomerAsync(email) {
    try {
      const customerResponse = await this.api.customers.getByUserAccount(
        `Account=${email}`
      );

      const response = await this.api.customers.findOne(
        customerResponse.data.data
      );

      return {
        id: response.data.data.id,
        fiscalName: response.data.data.fiscalName,
        fiscalCode: response.data.data.fiscalCode,
        fiscalEmail: response.data.data.contactEmail,
        postalCode: response.data.data.fiscalPostalCode,
        fiscalRegime: response.data.data.taxRegime,
      };
    } catch (error) {
      this.view.onError(error);
    }
  }

  async findCustomerContactAsync(email, customerContactId) {
    try {
      const customerResponse = await this.api.customers.getByUserAccount(
        `Account=${email}`
      );
      const response = await this.api.customersContact.findOne(
        customerResponse.data.data,
        customerContactId
      );

      return {
        id: response.data.data.id,
        fiscalName: response.data.data.fiscalName,
        fiscalCode: response.data.data.fiscalCode,
        fiscalEmail: response.data.data.contactEmail,
        postalCode: response.data.data.fiscalPostalCode,
        fiscalRegime: response.data.data.taxRegime,
      };
    } catch (error) {
      this.view.onError(error);
    }
  }

  async setContact(contact, type) {
    if (type === "receiver") {
      this.view.receiverCustomerLocations = [];
    } else {
      this.view.senderCustomerLocations = [];
    }

    // this.view.$set(this.view.formData[type], "customer", null);

    this.view.setContact({
      name: contact?.contactName,
      phone: contact?.contactPhone,
      email: contact?.contactEmail,
      id: contact?.id,
      type: type,
    });

    if (contact.id) {
      var customerResponse = await this.api.customerLocation.find(
        contact.id,
        `Skip=0&Limit=1000&IsActive=true`
      );
      if (type === "receiver") {
        this.view.receiverCustomerLocations = customerResponse.data.data;
      } else {
        this.view.senderCustomerLocations = customerResponse.data.data;
      }
    }
  }

  async cancelReceptionOrder(customerId, id) {
    return this.api.customerReceptions.cancelReceptionOrder(customerId, id);
  }

  drawMap(collection) {
    if (this.map) {
      GpsTrackingHelper.removeMap(this.map);
      this.map = GpsTrackingHelper.createMap([24.4058838, -105.9085404], 5);
    } else {
      this.map = GpsTrackingHelper.createMap([24.4058838, -105.9085404], 5);
    }

    GpsTrackingHelper.loadCopyRights(this.map);
    var customIcon = GpsTrackingHelper.createCustomIcon();
    collection.forEach((deviceLocation) => {
      GpsTrackingHelper.createMarker(
        this.map,
        customIcon,
        [deviceLocation.lat, deviceLocation.lng],
        deviceLocation.getMarkerContent()
      );
    });
    this.map.closePopup();
    this.view.isMapLoading = false;
  }

  startMap(formData) {
    // Paso 1: Ordenar los registros por fecha de forma descendente
    const sortedRecords = _.orderBy(formData.historyTrace, ["date"], ["desc"]);

    // Paso 2: Filtrar los registros con estatus 5
    const filteredRecords = _.filter(sortedRecords, { status: 5 });

    // Paso 3: Obtener el primer registro con estatus 5
    const firstRecordWithDeliveredStatus = _.head(filteredRecords);

    this.drawMap([
      {
        lat: formData.deliveryLat,
        lng: formData.deliveryLng,
        getMarkerContent: () => {
          return `
                          <strong>Estado:</strong><br />
                          ${firstRecordWithDeliveredStatus.traceStatusName.toUpperCase()} <br /><br /> 
                          <strong>COMENTARIOS:</strong><br />
                          ${firstRecordWithDeliveredStatus.comments}<br /><br />
                          <strong>FECHA Y HORA DE ENTREGA:</strong><br />
                          ${moment(firstRecordWithDeliveredStatus.date).format(
            "DD MMM YYYY hh:mm:ss a"
          )}<br />
                      `;
        },
      },
    ]);
  }

  getOrderDescending(list) {
    return _.orderBy(list, ["date"], ["desc"]);
  }

  getColorTrackingStatus(status) {
    return ReceptionHelper.getColorTrackingStatus(status);
  }

  getTraceStatusDescription(item) {
    var result = [];

    result.push(item.traceStatusName);

    if (item.traceStatusReason) {
      result.push(`Motivo: ${item.traceStatusReason}`);
    }

    if (item.comments) {
      result.push(`Observaciones: ${item.comments}`);
    }

    return result.join(" | ");
  }

  setPaymentMethods(formData, receptionServiceType, paymentMethods) {
    this.view.isFiscalLoading = true;
    setTimeout(() => {
      const paymentMethodCode = receptionServiceType === 1 ? "PPD" : "PUE";
      const paymentMethod = paymentMethods.find(
        (item) => item.code === paymentMethodCode
      );
      this.view.$set(formData.orderFiscal, "paymentMethod", paymentMethod);
      this.view.isFiscalLoading = false;
    }, 400);
  }

  setBillUsingTypeGeneralPublic(formData, billUsingTypes) {
    this.view.isFiscalLoading = true;
    setTimeout(() => {
      const defaultBillUsingType = billUsingTypes.find(item => item.code === "S01")
      this.view.$set(formData.orderFiscal, "billUsingType", defaultBillUsingType);
      this.view.isFiscalLoading = false;
    }, 400);
  }
}
