<script>
import ModalCustomerComponent from "./ModalCustomerComponent";
import ModalCustomerLocationView from "./ModalCustomerLocationView";
import ModalCustomerSubAccountView from "./ModalCustomerSubAccountView";
import ModalImportView from '../shared/modals/ModalImportView'

export default {
  components: {
    "modal-customer-location-view": ModalCustomerLocationView,
    "modal-customer-sub-account-view": ModalCustomerSubAccountView,
    "modal-import": ModalImportView
  },
  extends: ModalCustomerComponent,
};
</script>

<template>
  <div>
    <el-dialog
      custom-class="dialog-customer"
      :visible.sync="config.isVisible"
      :before-close="onCancel"
      :close-on-click-modal="false"
    >
      <span
        slot="title"
        class="dialog-title"
      >
        <i class="uil-comment-notes" /> Cliente
      </span>

      <el-form
        ref="formData"
        v-loading="config.isLoading"
        :element-loading-text="config.isLoadingText"
        element-loading-spinner="el-icon-loading"
        :model="formData"
        :rules="rules"
        :label-position="'top'"
      >
        <el-tabs
          v-model="customerTabActive"
          type="border-card"
        >
          <el-tab-pane
            label="Información General"
            name="general-info"
          >
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
              :span="24"
            >
              <el-col :span="5">
                <el-form-item
                  label="No. Cliente"
                  prop="code"
                >
                  <el-input
                    v-model="formData.code"
                    autocomplete="off"
                    size="medium"
                    :disabled="true"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  label="Descripción/Alias"
                  prop="description"
                >
                  <el-input
                    v-model="formData.description"
                    autocomplete="off"
                    size="medium"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  label="Descuento (%)"
                  prop="discount"
                >
                  <el-input-number
                    ref="discount"
                    v-model="formData.discountPercentage"
                    autocomplete="off"
                    autofocus="true"
                    size="medium"
                    :step="5"
                    :min="0"
                    :max="100"
                    step-strictly
                  />
                </el-form-item>
              </el-col>
              <el-col
                :span="5"
                align="center"
              >
                <el-form-item label="Estado">
                  <el-switch
                    v-model="formData.isActive"
                    active-text="Activo"
                    inactive-text="Inactivo"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-divider content-position="center">
              Datos de contacto
            </el-divider>
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
            >
              <el-col :span="9">
                <el-form-item
                  label="Nombre"
                  prop="contactName"
                >
                  <el-input
                    v-model="formData.contactName"
                    autocomplete="off"
                    size="medium"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item
                  label="Correo electrónico"
                  prop="contactEmail"
                >
                  <el-input
                    v-model="formData.contactEmail"
                    autocomplete="off"
                    size="medium"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item
                  label="Teléfono"
                  prop="contactPhone"
                >
                  <el-input
                    v-model="formData.contactPhone"
                    autocomplete="off"
                    size="medium"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-divider content-position="center">
              Datos Fiscales
            </el-divider>
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
            >
              <el-col :span="16">
                <el-form-item
                  label="Regimen Fiscal"
                  prop="taxRegime"
                >
                  <el-select
                    v-model="formData.taxRegime"
                    placeholder="Seleccionar"
                    filterable
                    clearable
                    debounce
                    value-key="id"
                    size="large"
                  >
                    <el-option
                      v-for="item in taxRegimes"
                      :key="item.id"
                      :label="item.getFullDescription()"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="Dirección Fiscal (C.P.)"
                  prop="fiscalPostalCode"
                >
                  <el-select
                    v-model="formData.fiscalPostalCode"
                    placeholder="Seleccionar"
                    filterable
                    clearable
                    debounce
                    value-key="id"
                    size="large"
                    remote
                    :remote-method="onFindPostalCodes"
                  >
                    <el-option
                      v-for="item in postalCodes"
                      :key="item.id"
                      :label="item.getFullDescription()"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
            >
              <el-col :span="18">
                <el-form-item
                  label="Razón Social"
                  prop="fiscalName"
                >
                  <el-input
                    v-model="formData.fiscalName"
                    autocomplete="off"
                    size="medium"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item
                  label="RFC"
                  prop="fiscalCode"
                >
                  <el-input
                    v-model="formData.fiscalCode"
                    placeholder="Ingresar R.F.C"
                    autocomplete="off"
                    size="medium"
                  >
                    <template slot="append">
                      <i
                        v-if="!isRfcSearching"
                        id="ic_search_rfc_icon"
                        class="el-icon-search"
                      />
                      <i
                        v-else
                        id="ic_search_rfc_icon"
                        class="el-icon-loading"
                      />
                    </template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane
            label="Ubicaciones"
            name="locations"
          >
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
            >
              <el-col
                :span="24"
                align="right"
              >
                <el-button-group>
                  <el-button
                    type="primary"
                    size="small"
                    @click="onCreateCustomerLocation"
                  >
                    <i class="fas fa-plus-circle" /> Agregar Ubicación
                  </el-button>
                  <el-dropdown @command="onChangeCustomerLocationOption">
                    <el-button size="small">
                      <i class="el-icon-more" />
                    </el-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          :command="{ command: 'import' }"
                        >
                          <i class="el-icon-upload" /> Importar Ubicaciones
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </el-button-group>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
            >
              <el-col ::span="24">
                <el-table
                  v-loading="isTableCustomerLocationLoading"
                  :data="formData.getCustomerLocations()"
                  :height="518"
                >
                  <el-table-column
                    prop="code"
                    label="Código Interno"
                    width="120"
                    align="center"
                  />
                  <el-table-column
                    prop="customerCode"
                    label="Código Cliente"
                    width="120"
                    align="center"
                  />
                  <el-table-column
                    prop="description"
                    label="Descripción/Alias"
                  />
                  <el-table-column label="Dirección">
                    <template #default="scope">
                      {{ scope.row.getFullAddress() }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Estado"
                    show-overflow-tooltip
                    align="center"
                    width="120"
                  >
                    <template #default="scope">
                      <el-tag
                        v-if="scope.row.isActive"
                        type="success"
                        effect="plain"
                      >
                        <i class="el-icon-success" /> Activo
                      </el-tag>
                      <el-tag
                        v-if="!scope.row.isActive"
                        type="danger"
                        effect="plain"
                      >
                        <i class="el-icon-error" /> Inactivo
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Opciones"
                    width="120"
                    align="center"
                    show-overflow-tooltip
                  >
                    <template #default="scope">
                      <el-dropdown @command="onChangeOptionCustomerLocation">
                        <el-button size="small">
                          <i class="el-icon-more" />
                        </el-button>
                        <template #dropdown>
                          <el-dropdown-menu>
                            <el-dropdown-item
                              :command="{
                                command: 'edit',
                                item: scope.row,
                                index: scope.$index,
                              }"
                            >
                              <i class="el-icon-edit" /> Mostrar
                            </el-dropdown-item>
                            <el-dropdown-item
                              :command="{
                                command: 'delete',
                                item: scope.row,
                                index: scope.$index,
                              }"
                            >
                              <i class="el-icon-delete" /> Eliminar
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </template>
                      </el-dropdown>
                    </template>
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane
            label="QR's de Verificación"
            name="verificationCode"
          >
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
            >
              <el-col ::span="24">
                <el-table
                  v-loading="isTableVerificationCodesLoading"
                  :data="formData.customerVerificationCodes"
                  :height="518"
                >
                  <el-table-column
                    prop="verificationCode"
                    label="Código"
                    width="200"
                    align="center"
                  />
                  <el-table-column
                    prop="description"
                    label="Descripción/Responsable"
                  />

                  <el-table-column
                    label="QR de Verificación"
                    show-overflow-tooltip
                    width="200"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-bottom"
                        @click="getVerificationCodeQr(scope.row.id)"
                      >
                        Descargar Código QR
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
              style="margin-top: 20px"
            >
              <el-col
                :span="8"
                align="left"
              >
                <p class="mb-sm-0">
                  Mostrar 1 a {{ paginationVerificationCode.limit }} de
                  {{ paginationVerificationCode.totalItems }} registros
                </p>
              </el-col>
              <el-col
                :span="16"
                align="right"
              >
                <el-pagination
                  :page-count="paginationVerificationCode.totalPages"
                  :current-page.sync="currentPageVerificationCode"
                  layout="prev, pager, next"
                  @current-change="onPageChangeVerificationCode"
                />
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane
            label="Cuenta Maestra"
            name="masterAccount"
          >
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
            >
              <el-col
                :span="6"
                align="center"
              >
                <el-form-item label="¿Activar Cuenta Maestra?">
                  <el-switch
                    v-model="formData.masterAccount.isActive"
                    active-text="Activo"
                    inactive-text="Inactivo"
                    :active-value="true"
                    :inactive-value="false"
                    @change="onChangeMasterAccount"
                  />
                </el-form-item>
              </el-col>
              <el-col
                :span="6"
                align="center"
              >
                <el-form-item label="¿Activar Subcuentas?">
                  <el-switch
                    v-model="formData.allowSubAccounts"
                    active-text="Activo"
                    inactive-text="Inactivo"
                    :active-value="true"
                    :inactive-value="false"
                  />
                </el-form-item>
              </el-col>
              <el-col
                v-if="showEditPass()"
                :span="6"
                align="center"
                style="padding-top: 30px;"
              >
                <el-form-item prop="editPass">
                  <el-checkbox
                    v-model="editPass"
                    border
                    :disabled="!formData.masterAccount.isActive"
                    @change="onClearFieldForm('formData')"
                  >
                    Editar Contraseña
                  </el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
            >
              <el-col :span="5">
                <el-form-item
                  label="Usuario"
                  prop="masterAccount.userName"
                >
                  <el-input
                    v-model="formData.masterAccount.userName"
                    autocomplete="off"
                    size="medium"
                    :disabled="!formData.masterAccount.isActive"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item
                  label="Correo electrónico"
                  prop="masterAccount.email"
                >
                  <el-input
                    v-model="formData.masterAccount.email"
                    autocomplete="off"
                    size="medium"
                    :disabled="!formData.masterAccount.isActive"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item
                  label="Contraseña"
                  prop="masterAccount.password"
                >
                  <el-input
                    v-model="formData.masterAccount.password"
                    autocomplete="off"
                    size="medium"
                    show-password
                    name="password"
                    :disabled="!isPasswordEnabled()"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item
                  label="Confirmar Contraseña"
                  prop="masterAccount.passwordConfirmation"
                >
                  <el-input
                    v-model="formData.masterAccount.passwordConfirmation"
                    autocomplete="off"
                    name="passwordConfirmation"
                    size="medium"
                    show-password
                    :disabled="!isPasswordEnabled()"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane
            v-if="formData.allowSubAccounts"
            label="Subcuentas"
            name="customerSubAccounts"
          >
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
            >
              <el-col :span="24">
                <el-button
                  type="primary"
                  size="small"
                  @click="oncreateCustomerSubAccount"
                >
                  <i class="fas fa-plus-circle" /> Agregar Cuenta
                </el-button>
              </el-col>
            </el-row>
            <el-row>
              <el-table
                v-loading="isTableCustomerSubAccountLoading"
                :data="formData.getCustomerSubAccounts()"
                :height="518"
              >
                <el-table-column label="Descripción">
                  <template #default="scope">
                    <div v-if="scope.row.accountName">
                      {{ scope.row.accountName }}
                    </div>
                    <div v-else>
                      {{ scope.row.user.accountName }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Usuario">
                  <template #default="scope">
                    {{ scope.row.user.userName }}
                  </template>
                </el-table-column>
                <el-table-column label="Correo">
                  <template #default="scope">
                    {{ scope.row.user.email }}
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
            <el-row
              type="flex"
              class="row-bg"
              :gutter="20"
              style="margin-top: 20px"
            >
              <el-col
                :span="8"
                align="left"
              >
                <p class="mb-sm-0">
                  Mostrar 1 a {{ paginationSubAccount.limit }} de
                  {{ paginationSubAccount.totalItems }} registros
                </p>
              </el-col>
              <el-col
                :span="16"
                align="right"
              >
                <el-pagination
                  :page-count="paginationSubAccount.totalPages"
                  :current-page.sync="currentPageSubAccount"
                  layout="prev, pager, next"
                  @current-change="onPageChangeSubAccount"
                />
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          @click="onCancel"
        >Cancelar</el-button>
        <el-button
          type="primary"
          size="small"
          :disabled="!onCanSaveOrUpdate()"
          @click="onSubmitForm('formData')"
        >Guardar</el-button>
      </span>
    </el-dialog>
    <modal-customer-location-view
      v-if="modalCustomerLocationConfig.isVisible"
      :form-data="itemCustomerLocation"
      :config="modalCustomerLocationConfig"
      :customer="formData"
    />
    <modal-customer-sub-account-view
      v-if="modalCustomerSubAccountConfig.isVisible"
      :form-data="itemCustomerSubAccount"
      :accounts="formData.customerSubAccounts"
      :config="modalCustomerSubAccountConfig"
    />

    <modal-import :conf="modalImportLocations" />
  </div>
</template>
