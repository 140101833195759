<script>
import ModalCustomerSpecialPriceComponent from '../components/ModalCustomerSpecialPriceComponent'
import ModalImportView from '../../shared/modals/ModalImportView'

export default {
  components: {
    ModalImportView
  },
  extends: ModalCustomerSpecialPriceComponent
}
</script>

<template>
  <div>
    <el-dialog
      :visible.sync="config.isVisible"
      :before-close="onCancel"
      :close-on-click-modal="false"
      width="70%"
    >
      <span
        slot="title"
        class="dialog-title"
      >
        <i class="uil-cog" /> {{ config.title }}
      </span>

      <el-row
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="8">
          <el-select
            v-model="customer"
            placeholder="Filstrar por Cliente"
            size="small"
            remote
            filterable
            clearable
            :remote-method="onFilterCustomers"
            value-key="id"
          >
            <el-option
              v-for="item in customers"
              :key="item.id"
              :label="item.description"
              :value="item"
            />
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select
            v-model="serviceCost"
            placeholder="Filtrar Por Servicio"
            size="small"
            remote
            filterable
            clearable
            :remote-method="onFilterServiceCosts"
            value-key="id"
          >
            <el-option
              v-for="item in serviceCosts"
              :key="item.id"
              :label="item.getFullDescriptionNoPriceObj()"
              :value="item"
            />
          </el-select>
        </el-col>
        <el-col
          :span="8"
          align="right"
        >
          <el-button-group>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-circle-plus-outline"
              @click="onAddSpecialPrice"
            >
              Agregar
              Precio
            </el-button>
            <el-button
              size="small"
              type="default"
              icon="el-icon-upload"
              @click="onShowImportSpecialPrice"
            >
              Importar
              Precios
            </el-button>
          </el-button-group>
        </el-col>
      </el-row>
      <el-divider />
      <el-row
        v-if="showSelectionMultiple"
        v-loading="isSelectionLoading"
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col
          :span="16"
          align="right"
          :offset="8"
        >
          <el-button-group>
            <el-button
              size="small"
              type="default"
              icon="el-icon-refresh"
              round
              @click="onImportSpecialPrice"
            >
              Guardar
              Seleccionados
            </el-button>
            <el-button
              size="small"
              type="danger"
              icon="el-icon-delete"
              round
              @click="onRemoveSelected"
            >
              Eliminar
              Seleccionados
            </el-button>
          </el-button-group>
        </el-col>
      </el-row>
      <el-divider v-if="showSelectionMultiple" />
      <el-row
        v-loading="pricesLoading"
        type="flex"
        class="row-bg"
        :gutter="20"
      >
        <el-col :span="24">
          <el-table
            ref="multipleSpecialPrices"
            v-loading="isTableLoading"
            :data="collection"
            style="width: 100%"
            height="450"
            @selection-change="onSelectionChange"
          >
            <el-table-column
              type="selection"
              width="30"
            />
            <el-table-column label="Cliente">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.customer"
                  placeholder="Buscar y seleccionar cliente"
                  size="small"
                  remote
                  filterable
                  clearable
                  :remote-method="onFindCustomers"
                  value-key="id"
                  @focus="onFocusComponent(scope.$index)"
                >
                  <el-option
                    v-for="item in scope.row.customers"
                    :key="item.id"
                    :label="item.description"
                    :value="item"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="Servicio">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.serviceCost"
                  placeholder="Buscar y seleccionar servicio"
                  size="small"
                  remote
                  filterable
                  clearable
                  :remote-method="onFindServiceCosts"
                  value-key="id"
                  @focus="onFocusComponent(scope.$index)"
                >
                  <el-option
                    v-for="item in scope.row.serviceCosts"
                    :key="item.id"
                    :label="item.getFullDescriptionNoPriceObj()"
                    :value="item"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="Precio Especial"
              width="160"
              align="center"
            >
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.amount"
                  size="small"
                  :min="0"
                  @focus="onFocusComponent(scope.$index)"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="Estado"
              width="180"
              align="center"
            >
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.isActive"
                  active-text="Activo"
                  inactive-text="Inactivo"
                  size="small"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="Opciones"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <el-button-group>
                  <el-button
                    type="default"
                    icon="el-icon-refresh"
                    size="small"
                    round
                    @click="onSaveSpecialPrice(scope.$index, scope.row)"
                  />
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="small"
                    round
                    @click="onRemoveSpecialPrice(scope.$index)"
                  />
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          @click="onCancel"
        >Aceptar</el-button>
      </span>
    </el-dialog>
    <ModalImportView
      v-if="modalImport.isVisible"
      :conf="modalImport"
    />
  </div>
</template>