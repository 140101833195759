import _ from 'lodash';
import BaseList from "../infraestructure/BaseList";
import SaleViewModel from "../../core/features/sales/SaleViewModel";

export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'Sale',
            viewModel: null,
            activeName: 'total',
            isMoreFilterVisible: false,
            filtersSelected: {
                folNumber: '',
                receptionNumber: '',
                saleDate: '',
                paymentType: '',
                billStatus: '',
                total: [0, 0],
            },
            maxTotal: 0,
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: "IMPORTAR VENTAS",
                onUploadImport: "on-upload-import-sales",
                onCancelImport: "on-cancel-import-sales",
            },
            modalBilling: {
                isLoading: false,
                isVisible: false,
            },
            modalFinancialHistory: {
                isVisible: false,
                isLoading: false,
                title: 'RESUMEN FINANCIERO DE SERVICIOS',
            },
            paymentTypeStatus: [],
            saleBillStatus: [],
            isAutoDigitalInvoice: false
        };
    },
    mounted() {
        this.onMountedEvent("on-submited-sale", this.onSearch);
        this.onMountedEvent("on-upload-import-sales", this.onUploadImport);
        this.onMountedEvent("on-cancel-import-sales", this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent("on-submited-sale", this.onSearch);
        this.onBeforeDestroyEvent("on-upload-import-sales", this.onUploadImport);
        this.onBeforeDestroyEvent("on-cancel-import-sales", this.onCancelImport);
    },
    created() {
        this.viewModel = new SaleViewModel(this);
        this.onActive();
    },
    methods: {
        async onActive() {
            this.isListLoading = true;
            await this.viewModel.bindList()
        },
        onSelectSale() { },
        onCreate() {
            this.item = this.viewModel.create({
                isActive: true,
                customer: null,
                saleBill: {
                    consignmentNote: {
                        version: {},
                        internationalTransport: {},
                        autotransportation: {
                            vehicles: [],
                        },
                        transportationFigure: {
                            drivers: [],
                        },
                        destination: {},
                        origin: {},
                    },
                    saleBillPayments: []
                },
                consignmentNote: {
                    version: {},
                    internationalTransport: {},
                    autotransportation: {
                        vehicles: [],
                    },
                    transportationFigure: {
                        drivers: [],
                    },
                    destination: {},
                    origin: {},
                },
                receptions: [],
                concepts: [],
                saleConcepts: [],
            });
            this.modalConfig.isVisible = true;
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByFolNumber: _.debounce(function (text) {
            if (text) {
                this.addFilter('FolNumber', text)
            } else {
                this.removeFilter('FolNumber')
            }
            this.onSearch();
        }, 500),
        onSearchByReceptionNumber: _.debounce(function (text) {
            if (text) {
                this.addFilter('ReceptionTrackingNumber', text)
            } else {
                this.removeFilter('ReceptionTrackingNumber')
            }
            this.onSearch();
        }, 500),
        searchSaleDateFilter: _.debounce(function (text) {
            if (text) {
                this.addFilter('StartDate', text[0])
                this.addFilter('EndDate', text[1])
            } else {
                this.removeFilter('StartDate')
                this.removeFilter('EndDate')
            }
            this.onSearch();
        }, 500),
        searchTotalFilter(data) {
            this.addFilter('TotalMin', data[0])
            this.addFilter('TotalMax', data[1])
            this.onSearch()
        },
        searchpaymentTypeFilter(data) {
            if (data !== null) {
                this.addFilter('PaymentTypeId', data)
            } else {
                this.removeFilter('PaymentTypeId')
            }
            this.onSearch()
        },
        serachBillStatusFilter(data) {
            if (data !== null) {
                this.addFilter('SaleBillStatus', data)
            } else {
                this.removeFilter('SaleBillStatus')
            }
            this.onSearch()
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
        formatTooltip(val) {
            if (val === this.searchTotal[0]) {
                return 'Mín:' + val
            } else {
                return 'Máx: ' + val
            }
        },
        onSearchResponseMaxCost(response){
            if (this.maxTotal === 0) {
                this.maxTotal = response.maxTotal
                this. searchTotal= [0,this.maxTotal]
            }
        },
        onChangeOption(event) {
            if (event.command === "edit") this.onEdit(event.item.id);
            if (event.command === "delete") this.onDelete(event.item.id);
            if (event.command === "do-bill") this.onDoBill(event.item.id);
            if (event.command === "do-bill-cancellation") this.onDoBillCancellation(event.item.id);
            if (event.command === "download-pdf") this.onDownloadPdf(event.item);
            if (event.command === "download-xml") this.onDownloadXml(event.item);
        },
        onDownloadPdf(item) {
            this.isListLoading = true;
            setTimeout(() => {
                const blobPdf = this.viewModel.dataURItoBlob(item.pdfFile, "application/pdf");
                const urlPdf = URL.createObjectURL(blobPdf);
                const link = document.createElement("a");
                link.href = urlPdf;
                link.target = "_blank";
                link.setAttribute("download", `factura_folio_${item.folNumber}.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
            }, 200);
            setTimeout(() => {
                this.isListLoading = false;
            }, 400);
        },
        onDownloadXml(item) {
            this.isListLoading = true;
            const blobXml = this.viewModel.dataURItoBlob(item.xmlFile, "application/xml");
            const urlPdf = URL.createObjectURL(blobXml);
            const link = document.createElement("a");
            link.href = urlPdf;
            link.target = "_blank";
            link.setAttribute("download", `factura_folio_${item.folNumber}.xml`); //or any other extension
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
                this.isListLoading = false;
            }, 400);
        },
        onDoBill(id) {
            this.isListLoading = true;
            this.viewModel.doBill(id);
        },
        onDoBillResponse(response) {
            this.notifySuccess(response.message)
            this.onSearch();
        },
        onDoBillError(error) {
            setTimeout(() => {
                this.isListLoading = false;
                this.onError(error)
            }, 400);
        },
        onDoBillCancellation(id) {
            this.isListLoading = true;
            this.viewModel.doBillCancellation(id);
        },
        onDoBillCancellationResponse(response) {
            this.notifySuccess(response.message)
            this.onSearch();
        },
        onDoBillCancellationError(error) {
            setTimeout(() => {
                this.isListLoading = false;
                this.onError(error)
            }, 400);
        },
        onShowBilling() {
            this.modalBilling.isVisible = true;
        },
        getBillStatusStyle(status) {
            if (status === 1) return "success";
            if (status === 2) return "danger";
            if (status === 3) return "info";
            if (status === 4) return "primary";
            if (status === 5) return "primary";
            return "default";
        },
        getBillStatusIcon(status) {
            if (status === 1) return "el-icon-success";
            if (status === 2) return "el-icon-error";
            if (status === 3) return "el-icon-warning";
            if (status === 4) return "el-icon-refresh";
            if (status === 5) return "el-icon-refresh";
            return "default";
        },
        onOpenFinancialHistory() {
            this.modalFinancialHistory.isVisible = true
        },
        isBillActive(billStatus) {
            return (billStatus === 1)
        },
        onRestructureLocations(){
            this.isLoading = true
            this.viewModel.updateRestructureLocations()
        },
        onRestructureLocationsResponse(response){
            setTimeout(() => {
                this.notifySuccess(response.message)
                this.isLoading = false
            }, 400);
        },
        async onChangeAutoDigitalInvoice(value){
            await this.viewModel.updateAutoDigitalInvoice(value)
        }
    },
};
