import BaseForm from "../infraestructure/BaseForm";
import CustomerReceptionsViewModel from "../../core/features/customersReceptions/CustomerReceptionsViewModel";
import _ from "lodash";
export default {
  extends: BaseForm,
  data() {
    return {
      receptionData: null,
      viewModel: null,
      isLoading: false,
      sendDataTabActive: "sender",
      shipmentTabActive: "1",
      weightCostConfig: null,
      useCustomerData: "none",
      taxRegimes: [],
      paymentMethods: [],
      billUsingTypes: [],
      payerResponsibles: [
        {
          id: "sender",
          name: "Remitente",
          icon: "uil-sign-out-alt",
        },
        {
          id: "receiver",
          name: "Destinatario",
          icon: "uil-sign-in-alt",
        },
      ],
      rulesStep0: {
        receptionServiceType: [
          {
            validator: this.onReceptionServiceTypeValidation,
            trigger: "blur, change",
          },
        ],
        payerResponsible: [
          {
            required: true,
            message: "Responsable de pago requerido.",
            trigger: "blur",
          },
        ],
        sender: {
          customer: [
            {
              validator: this.onSenderCustomerValidation,
              trigger: "blur, change",
            },
          ],
          customerLocation: [
            {
              validator: this.onSenderCustomerLocationValidation,
              trigger: "blur, change",
            },
          ],
          name: [
            {
              required: true,
              message: "Nombre remitente requerido.",
              trigger: "blur",
            },
          ],
          phone: [
            {
              required: true,
              message: "Teléfono remitente requerido.",
              trigger: "blur",
            },
          ],
          email: [
            {
              type: "email",
              message: "Correo electrónico remitente no valido.",
              trigger: "blur",
            },
          ],
          observation: [
            {
              required: true,
              message: "Referencia/Observación remitente requerido.",
              trigger: "blur",
            },
            {
              validator: this.onSenderSizeValidation,
              trigger: "blur, change",
            },
          ],
          type: [
            {
              required: true,
              message: "Opción de información de remitente requerido.",
              trigger: "blur",
            },
          ],
        },
        receiver: {
          customer: [
            {
              validator: this.onReceiverCustomerValidation,
              trigger: "blur, change",
            },
          ],
          customerLocation: [
            {
              validator: this.onReceiverCustomerLocationValidation,
              trigger: "blur, change",
            },
          ],
          name: [
            {
              required: true,
              message: "Nombre destinatario requerido.",
              trigger: "blur",
            },
          ],
          phone: [
            {
              required: true,
              message: "Telefono destinatario requerido.",
              trigger: "blur",
            },
          ],
          email: [
            {
              type: "email",
              message: "Correo electrónico destinatario no valido.",
              trigger: "blur",
            },
          ],
          observation: [
            {
              required: true,
              message: "Referencia/Observación destinatario requerido.",
              trigger: "blur",
            },
            {
              validator: this.onReceiverSizeValidation,
              trigger: "blur, change",
            },
          ],
          type: [
            {
              required: true,
              message: "Opción de información de destinatario requerido.",
              trigger: "blur",
            },
          ],
        },
        orderFiscal: {
          fiscalName: [
            {
              validator: this.onOrderFiscalNameValidator,
              trigger: "blur, change",
            },
          ],
          fiscalCode: [
            {
              validator: this.onOrderFiscalCodeValidator,
              trigger: "blur, change",
            },
          ],
          fiscalEmail: [
            {
              validator: this.onOrderFiscalEmailValidator,
              trigger: "blur, change",
            },
          ],
          fiscalPostalCode: [
            {
              validator: this.onOrderFiscalPostalCodeValidator,
              trigger: "blur, change",
            },
          ],

          billUsingType: [
            {
              validator: this.onOrderFiscalBillUsingTypeValidator,
              trigger: "blur, change",
            },
          ],
          paymentMethod: [
            {
              validator: this.onOrderFiscalPaymentMethodValidator,
              trigger: "blur, change",
            },
          ],
          type: [
            {
              required: true,
              message: "Opción de información de facturacion requerido.",
              trigger: "blur",
            },
          ],
        },
      },
      rulesStep1: {
        /*  receptionType: [
          {
            validator: this.onReceptionTypeValidation,
            trigger: "blur, change",
          },
        ], */
        serviceCost: [
          {
            validator: this.onServiceCostValidation,
            trigger: "blur, change",
          },
        ],
        traces: [
          {
            validator: this.onReceptionPackagesValidation,
            trigger: "blur, change",
          },
        ],
        originPlace: [
          {
            validator: this.onOriginPlaceValidation,
            trigger: "blur, change",
          },
        ],
        destinationPlace: [
          {
            validator: this.onDestinationPlaceValidation,
            trigger: "blur, change",
          },
        ],
      },
      rulesStep2: {
        paymentType: [
          {
            required: true,
            message: "Forma de pago requerido.",
            trigger: "blur, change",
          },
        ],
      },
      itemReceptionsCustomer: "",
      customerSolvency: [],
      stepActive: 0,
      senderContacts: [],
      receiverContacts: [],
      senderCustomerLocations: [],
      receiverCustomerLocations: [],
      serviceCosts: [],
      origins: [],
      destinations: [],
      itemReceptionPackage: null,
      modalReceptionPackageConfig: {
        isVisible: false,
        isLoading: false,
      },
      modalReceptionsCustomerConfig: {
        isVisible: false,
        isLoading: false,
        type: null,
      },
      receptionPackageSelectedIndex: null,
      receptionTypes: CustomerReceptionsViewModel.receptionTypes,
      receptionServiceTypes: CustomerReceptionsViewModel.receptionServiceTypes,
      paymentTypes: CustomerReceptionsViewModel.paymentTypes,
      receptionStatuses: CustomerReceptionsViewModel.receptionStatuses,
      senderData: "account",
      receiverData: "contacts",
      defaultFiscal: null,
      customerId: null,
      fiscalPostalCodes: [],
      isFiscalLoading: false,
    };
  },
  props: {
    formData: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    submitForm: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    this.onMountedEvent(
      "on-submited-customer-reception-packages",
      this.onUpsertReceptionPackage
    );
    this.onMountedEvent(
      "on-submited-receptions-customer",
      this.onCreateCustomerResponse
    );
  },
  beforeDestroy() {
    this.onBeforeDestroyEvent(
      "on-submited-customer-reception-packages",
      this.onUpsertReceptionPackage
    );
    this.onBeforeDestroyEvent(
      "on-submited-customer-receptions-customer",
      this.onCreateCustomerResponse
    );
  },
  created() {
    this.viewModel = new CustomerReceptionsViewModel(this);
    this.onActive();
  },
  methods: {
    onActive() {
      this.viewModel.bindFormAsync(
        this.formData,
        this.$store.getters["admin/getUserActive"]
      );
    },
    isFirstStep() {
      return this.stepActive === 0;
    },
    onPrevStep() {
      this.stepActive -= 1;
    },
    onNextStep() {
      this.$forceUpdate();
      if (this.stepActive < 2) {
        this.isStepValid(this.stepActive, (isValid) => {
          if (isValid) {
            this.isLoading = true;
            setTimeout(() => {
              this.stepActive += 1;

              if (this.stepActive == 2) {
                // this.config.isLoading = true;
                this.viewModel.save(this.formData);
              } else {
                this.isLoading = false;
              }
            }, 400);
          }
        });
      }
    },
    isStepValid(stepActive, callback) {
      this.$refs[`formDataStep${stepActive}`].validate((isValid, errors) => {
        if (!isValid) {
          this.onShowWarningList(errors);
        }
        callback(isValid);
      });
    },
    onSaveResponse(response) {
      this.viewModel.requestPickingPdf(response.customerId, response.orderId);
    },
    onSaveError(error) {
      this.config.isLoading = false;
      this.isLoading = false;
      this.stepActive -= 1;
      this.onError(error);
    },
    onRequestPickingPdfResponse(response) {
      setTimeout(() => {
        this.isLoading = false;
        this.config.isLoading = false;
        this.receptionData = response;
      }, 400);
    },
    onRequestPickingPdfError(error) {
      setTimeout(() => {
        this.config.isLoading = false;
        this.onError(error);
      }, 400);
    },
    onFindItemResponseCustomerReception(response) {
      setTimeout(() => {
        this.receptionData = this.viewModel.mapReceptionData(response.data);
        this.config.isLoading = false;
        this.onDoAfterLocalSaveNoHideResponse(
          this.receptionData,
          "on-submited-customer-reception"
        );
      }, 2000);
    },
    onCreateReceptionPackage() {
      try {
        if (!this.viewModel.isReceptionSeviceTypeSelected(this.formData))
          throw new Error("Tipo de Recepción requerido");

        if (!this.viewModel.isServiceCostSelected(this.formData))
          throw new Error("Flete requerido");

        this.itemReceptionPackage = this.viewModel.createReceptionPackage({
          isActive: true,
          quantity: 1,
          weight: 1,
          height: 1,
          width: 1,
          length: 1,
        });

        this.modalReceptionPackageConfig.isVisible = true;
      } catch (error) {
        this.notifyWarning(error.message);
      }
    },
    onCreateModalRecepctionsCustomer(type) {
      this.itemReceptionsCustomer = this.viewModel.createReceptionCustomer({
        isActive: true,
      });
      this.modalReceptionsCustomerConfig.type = type;
      this.modalReceptionsCustomerConfig.isVisible = true;
    },
    onCreateCustomerResponse(response) {
      this.isLoading = true;
      this.viewModel.findCustomer(response.id, response.type);
    },
    onFindCustomerReceptionLocation(response) {
      var mappedCustomerLocations = this.viewModel.mapCustomerLocations(
        response.data
      );
      this.senderCustomerLocations = mappedCustomerLocations;
    },
    /* onCustomerSenderResponse(response) {
      this.senderCustomers = [response.data];
      var mappedCustomerLocations = this.viewModel.mapCustomerLocations(
        response.data.customerLocations
      );
      this.senderCustomerLocations = mappedCustomerLocations[0]
        ? [mappedCustomerLocations[0]]
        : [];
      this.$set(this.formData, "senderCustomer", response.data);
      this.$set(
        this.formData,
        "senderCustomerLocation",
        mappedCustomerLocations[0]
      );
      this.$set(this.formData, "senderName", response.data.contactName);
      this.$set(this.formData, "senderPhone", response.data.contactPhone);
      this.$set(this.formData, "senderEmail", response.data.contactEmail);
      setTimeout(() => {
        this.isLoading = false;
      }, 600);
    },
    onCustomerReceiverResponse(response) {
      this.receiverCustomers = [response.data];
      var mappedCustomerLocations = this.viewModel.mapCustomerLocations(
        response.data.customerLocations
      );
      this.receiverCustomerLocations = mappedCustomerLocations[0]
        ? [mappedCustomerLocations[0]]
        : [];
      this.$set(this.formData, "receiverCustomer", response.data);
      this.$set(
        this.formData,
        "receiverCustomerLocation",
        mappedCustomerLocations[0]
      );
      this.$set(this.formData, "receiverName", response.data.contactName);
      this.$set(this.formData, "receiverPhone", response.data.contactPhone);
      this.$set(this.formData, "receiverEmail", response.data.contactEmail);
      setTimeout(() => {
        this.isLoading = false;
      }, 600);
    },*/
    onUpsertReceptionPackage(data) {
      this.viewModel.upsertReceptionPackage(
        this.formData.traces,
        data,
        this.receptionPackageSelectedIndex
      );
    },
    onChangeOptionReceptionPackage(event) {
      if (event.command === "edit") this.onEditReceptionPackage(event);
      if (event.command === "delete") this.onDeleteReceptionPackage(event);
    },
    onEditReceptionPackage(event) {
      this.itemReceptionPackage = this.viewModel.editReceptionPackage(
        event.item
      );

      this.itemReceptionPackage.serviceDeliveryPrice = this.formData.serviceDeliveryPrice;
      this.receptionPackageSelectedIndex = event.index;
      this.modalReceptionPackageConfig.isVisible = true;
    },
    onDeleteReceptionPackage(event) {
      this.$confirm("¿Desea eliminar el registro seleccionado?", "Eliminar", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.viewModel.deleteReceptionPackage(
            this.formData.traces,
            event.index
          );
        })
        .catch(() => {});
    },
    onFindSenderCustomerLocationsResponse(response) {
      this.$set(
        this.formData,
        "discountPercentage",
        response.data.discountPercentage
      );
      this.senderCustomerLocations = this.viewModel.mapCustomerLocations(
        response.data.customerLocations
      );
    },
    onFindReceiverCustomers(data) {
      this.receiverCustomers = [];
      if (data) {
        this.viewModel.findReceiverCustomers(`Description=${data}`);
      }
    },
    onFindReceiverCustomersResponse(response) {
      this.receiverCustomers = this.viewModel.mapCustomers(response.data);
    },
    onChangeReceptionServiceType(receptionServiceType) {
      this.viewModel.setPaymentMethods(this.formData, receptionServiceType, this.paymentMethods)
      if (receptionServiceType && receptionServiceType != 3) {
        this.onFindAvailableCustomerSolvency(receptionServiceType);
      } else {
        this.$refs.formDataStep0.clearValidate(["receptionServiceType"]);
      }
    },
    onFindReceiverCustomerLocationsResponse(response) {
      this.receiverCustomerLocations = this.viewModel.mapCustomerLocations(
        response.data.customerLocations
      );
    },
    onChangeServiceCost(serviceCost) {
      if (serviceCost) {
        this.$set(
          this.formData,
          "serviceDeliveryPrice",
          this.viewModel.getServiceDeliveryPrice(
            this.formData,
            serviceCost.amount
          )
        );
        // this.viewModel.findWeightCosts();
      } else {
        this.formData.serviceDeliveryPrice = 0;
      }
    },
    onFindWeightCostsResponse(response) {
      if (response.data.length === 0) {
        throw new Error("No hay parametros de peso configurados");
      } else {
        // this.weightCostConfig = response.data[0];
        this.onCalculatePackages(this.formData.serviceDeliveryPrice);
      }
    },
    onFindServiceCostsResponse(response) {
      this.serviceCosts = this.viewModel.mapServiceCosts(response.data);
    },
    onGetNameReceptionType(type) {
      return this.viewModel.getNameReceptionType(type);
    },
    onGetNamePaymentType(type) {
      return this.viewModel.getNamePaymentType(type);
    },
    onGetNameClientType(type) {
      return this.viewModel.getNameClientType(type);
    },
    onGetNameTrackingStatus(status) {
      return this.viewModel.getNameTrackingStatus(status);
    },
    onGetIconTrackingStatus(status) {
      return this.viewModel.getIconTrackingStatus(status);
    },
    onPrint() {
      this.viewModel.printTrackingDocument(this.receptionData.id);
    },
    onPrintTrackingDocumentResponse(response) {
      this.viewModel.mapPdfFile(
        response,
        `guia_${this.receptionData.trackingNumber}`
      );
      this.onDoAfterSaveResponse("Guía Generada.", "on-submited-receptions");
    },
    hasReceiverCustomer(receptionData) {
      return (
        receptionData.receiverLocation &&
        receptionData.receiverLocation.customer
      );
    },
    hasReceiverCustomerLocation(receptionData) {
      return (
        receptionData.receiverLocation &&
        receptionData.receiverLocation.mainStreet
      );
    },
    onCalculatePackages(serviceDeliveryPrice) {
      this.formData.traces = this.viewModel.calculatePackages(
        this.formData.traces,
        serviceDeliveryPrice
      );
      this.$forceUpdate();
    },
    getSummaries(param) {
      return this.viewModel.calculateSummary(param);
    },
    onFindAvailableCustomerSolvency(data) {
      if (data) this.viewModel.findAvailableCustomerSolvency(data);
    },
    onFindByAilableCustomerSolvencyResponse(response) {
      this.$set(
        this.formData,
        "customerSolvencyId",
        response.customerSolvencyId
      );
      this.$set(this.formData, "paymentType", response.paymentType);
      this.$set(
        this.formData,
        "serviceDeliveryPrice",
        response.serviceDeliveryPrice
      );
      this.$set(this.formData, "solvencyType", response.solvencyType);
      this.$set(this.formData, "solvencyMessage", response.message);
      this.$set(this.formData, "solvencyAvailable", response.isAvailable);
      if (
        response.solvencyType ===
        CustomerReceptionsViewModel.SolvencyType.Prepaid
      ) {
        this.$set(this.formData, "freightPayable", true);
      }
      this.$forceUpdate();
    },
    onChangeReceptionType(data) {
      this.$set(this.formData, "receptionStatus", data);
      this.$forceUpdate();
    },
    onChangePaymentType() {
      this.$forceUpdate();
    },
    onChangeReceptionStatus() {
      this.$forceUpdate();
    },
    onChangeSenderCustomerLocation(data) {
      // this.removeAddress('sender.customerLocation')
      if (data) {
        var customerId = this.formData.sender.customer
          ? this.formData.sender.customer.id
          : this.formData.customerId;
        this.viewModel.findLocation(customerId, data.id, (response) => {
          this.formData.sender.customerLocation = response.data;
          /* Object.keys(response.data).forEach((key) => {
            this.$set(this.formData.sender.selectedLocation, key, response.data[key]);
          });*/
          // this.$forceUpdate()
        });
      }
    },
    onChangeReceiverCustomerLocation(data) {
      // this.removeAddress('receiver.customerLocation')
      if (data) {
        var customerId = this.formData.receiver.customer
          ? this.formData.receiver.customer.id
          : this.formData.customerId;
        this.viewModel.findLocation(customerId, data.id, (response) => {
          this.formData.receiver.customerLocation = response.data;
          /* Object.keys(response.data).forEach((key) => {
            this.$set(this.formData.receiver.selectedLocation, key, response.data[key]);
          });
          this.$forceUpdate() */
        });
      }
    },
    onFindOrigins(data) {
      this.origins = [];
      if (data) {
        this.viewModel.findOrigins(`Name=${data}`);
      }
    },
    onFindOriginsResponse(response) {
      this.origins = this.viewModel.mapPlaces(response.data);
    },
    onFindDestinations(data) {
      this.destinations = [];
      if (data) {
        this.viewModel.findDestinations(`Name=${data}`);
      }
    },
    onFindDestinationsResponse(response) {
      this.destinations = this.viewModel.mapPlaces(response.data);
    },
    onReceptionServiceTypeValidation(rule, value, callback) {
      if (value) {
        if (this.formData.receptionServiceType != 3) {
          if (this.formData.solvencyAvailable) {
            callback();
          } else {
            callback(this.formData.solvencyMessage);
          }
        }
        callback();
      } else {
        callback("Tipo de servicio requerido");
      }
    },
    onReceptionTypeValidation(rule, value, callback) {
      if (!value) {
        callback("Tipo de recepción requerido");
      }
      callback();
    },
    onServiceCostValidation(rule, value, callback) {
      if (!this.formData.solvencyType || this.formData.solvencyType !== 5) {
        if (!value) {
          callback("Flete requerido");
        }
      }
      callback();
    },
    onReceptionPackagesValidation(rule, value, callback) {
      if (value.length == 0) {
        callback("Al menos 1 paquete requerido");
      }
      callback();
    },
    onSenderCustomerValidation(rule, value, callback) {
      if (this.formData.sender.type === "contacts") {
        if (this.formData.sender.customer) {
          callback();
        } else {
          callback(new Error("Contacto remitente requerido"));
        }
      } else {
        callback();
      }
    },
    onReceiverCustomerValidation(rule, value, callback) {
      if (this.formData.receiver.type === "contacts") {
        if (this.formData.receiver.customer) {
          callback();
        } else {
          callback(new Error("Contacto destinatario requerido"));
        }
      } else {
        callback();
      }
    },
    onLocationValidation(rule, value, callback) {
      if (_.isNil(value)) {
        callback("Dirección requerido");
      } else {
        callback();
      }
    },
    onReceiverLocationValidation(rule, value, callback) {
      if (_.isNil(value)) {
        callback("Dirección requerido");
      } else {
        callback();
      }
      callback();
    },
    onOriginPlaceValidation(rule, value, callback) {
      if (this.viewModel.isSolvencyPrepaid(this.formData)) {
        if (!value) {
          callback("Lugar de Origen requerido");
        } else {
          callback();
        }
      }
      callback();
    },
    onDestinationPlaceValidation(rule, value, callback) {
      if (this.viewModel.isSolvencyPrepaid(this.formData)) {
        if (!value) {
          callback("Lugar de Destino requerido");
        } else {
          callback();
        }
      }
      callback();
    },
    onFormatServiceCost(serviceCost) {
      return this.viewModel.formatServiceCost(
        serviceCost,
        this.formData.receptionServiceType,
        this.formData.serviceDeliveryPrice
      );
    },
    onFiscalOptionChange(type) {
      this.isFiscalLoading = true;
      setTimeout(async () => {
        const fiscalInformation = await this.viewModel.updateFiscalInformation(
          type,
          this.formData
        );
        if (fiscalInformation) this.setFiscalData(fiscalInformation);
      }, 400);
    },
    setFiscalData(data) {
      this.$set(this.formData.orderFiscal, "fiscalCustomerId", data.id);
      this.$set(this.formData.orderFiscal, "fiscalName", data.fiscalName);
      this.$set(this.formData.orderFiscal, "fiscalCode", data.fiscalCode);
      this.$set(this.formData.orderFiscal, "fiscalEmail", data.fiscalEmail);
      this.$set(this.formData.orderFiscal, "fiscalRegime", data.fiscalRegime);
      this.$set(this.formData.orderFiscal, "paymentMethod", null);
      this.$set(this.formData.orderFiscal, "billUsingType", null);

      this.viewModel.setPaymentMethods(this.formData, this.formData.receptionServiceType, this.paymentMethods)

      if (this.formData.orderFiscal.type === 'none') { 
        this.viewModel.setBillUsingTypeGeneralPublic(this.formData, this.billUsingTypes)
      }
      
      this.fiscalPostalCodes = data.postalCode ? [data.postalCode] : [];
      this.$set(this.formData.orderFiscal, "postalCode", data.postalCode);

      setTimeout(() => {
        this.isFiscalLoading = false;
      }, 400);
    },
    showFiscalInformacion() {
      return (
        this.formData &&
        this.formData.orderFiscal &&
        this.formData.orderFiscal.fiscalName &&
        this.formData.orderFiscal.fiscalCode
      );
    },
    isOrderFiscalEditable() {
      return this.useCustomerData !== "none";
    },
    generateTrackingDocument(orderNumber) {
      this.isLoading = true;
      this.viewModel.findOrderTrackingDocument(orderNumber);
    },
    onGenerateTrackingDocumentResponse(response) {
      setTimeout(() => {
        this.viewModel.addDownloadEvent(
          response.data,
          response.fileName,
          "application/pdf",
          () => {
            this.notifySuccess("Etiquetas Generadas.");
            this.isLoading = false;
          }
        );
      }, 400);
    },
    onGenerateTrackingDocumentError(error) {
      setTimeout(() => {
        this.isLoading = false;
        this.onError(error);
      }, 400);
    },
    onSenderOptionChange(data) {
      try {
        this.senderContacts = [];
        this.senderCustomerLocations = [];
        this.$set(this.formData.sender, "customer", null);
        this.$set(this.formData.sender, "customerLocation", null);
        this.$forceUpdate();
        if (data && data === "contacts") {
          this.setContact({
            name: "",
            phone: "",
            email: "",
            id: null,
            type: "sender",
          });
          this.viewModel.findCustomerContacts(
            this.formData.customerId,
            `Skip=0&Limit=10000`,
            this.onFindSenderCustomerContactsResponse
          );
        } else {
          let currentSession = this.$store.getters["admin/getUserActive"];
          this.setContact({
            name: currentSession.contactName,
            phone: currentSession.contactPhone,
            email: currentSession.contactEmail,
            id: currentSession.customerId,
            type: "sender",
          });
          this.viewModel.findLocations(
            this.formData.customerId,
            `Skip=0&Limit=10000`,
            this.onFindSenderLocationsResponse
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    onFindSenderCustomerContactsResponse(response) {
      this.senderContacts = response.data;
    },
    onChangeSenderContact(contact) {
      this.viewModel.setContact(contact, "sender");
    },
    onFindSenderLocationsResponse(response) {
      this.senderCustomerLocations = response.data;
    },
    onReceiverOptionChange(data) {
      this.receiverContacts = [];
      this.receiverCustomerLocations = [];
      this.$set(this.formData.receiver, "customer", null);
      this.$set(this.formData.receiver, "customerLocation", null);
      // this.removeAddress('receiver')
      if (data && data === "contacts") {
        this.setContact({
          name: "",
          phone: "",
          email: "",
          id: null,
          type: "receiver",
        });
        this.viewModel.findCustomerContacts(
          this.formData.customerId,
          `Skip=0&Limit=10000`,
          this.onFindReceiverCustomerContactsResponse
        );
      } else {
        let currentSession = this.$store.getters["admin/getUserActive"];
        this.setContact({
          name: currentSession.contactName,
          phone: currentSession.contactPhone,
          email: currentSession.contactEmail,
          id: currentSession.customerId,
          type: "receiver",
        });
        this.viewModel.findLocations(
          this.formData.customerId,
          `Skip=0&Limit=10000`,
          this.onFindReceiverLocationsResponse
        );
      }
    },
    onChangeReceiverContact(contact) {
      this.viewModel.setContact(contact, "receiver");
    },
    onFindReceiverCustomerContactsResponse(response) {
      this.receiverContacts = response.data;
    },
    onFindReceiverLocationsResponse(response) {
      this.receiverCustomerLocations = response.data;
    },
    setContact({ name, phone, email, id, type } = {}) {
      this.$set(this.formData[type], "customerLocation", {});
      this.$set(this.formData[type], "name", name);
      this.$set(this.formData[type], "phone", phone);
      this.$set(this.formData[type], "email", email);
      this.$set(this.formData[type], "id", id);
      this.$forceUpdate();
    },
    onChangePayerResponsible() {
      // this.cleanCustomer('sender');
      // this.cleanCustomer('receiver');
    },
    removeAddress(type) {
      this.$set(this.formData[type], "intNumber", null);
      this.$set(this.formData[type], "extNumber", null);
      this.$set(this.formData[type], "mainStreet", null);
      this.$set(this.formData[type], "secondaryStreet", null);
      this.$set(this.formData[type], "neighborhood", null);
      this.$set(this.formData[type], "city", null);
      this.$set(this.formData[type], "municipality", null);
      this.$set(this.formData[type], "district", null);
      this.$set(this.formData[type], "country", null);
      this.$set(this.formData[type], "postalCode", null);
    },
    onOrderFiscalNameValidator(rules, value, callback) {
      if (this.useCustomerData === "none") {
        callback();
      } else {
        if (value) {
          callback();
        } else {
          callback(new Error("Razón Social requerido."));
        }
      }
    },
    onOrderFiscalCodeValidator(rules, value, callback) {
      if (this.useCustomerData === "none") {
        callback();
      } else {
        if (value) {
          callback();
        } else {
          callback(new Error("R.F.C. requerido."));
        }
      }
    },
    onOrderFiscalEmailValidator(rules, value, callback) {
      if (this.useCustomerData === "none") {
        callback();
      } else {
        if (value) {
          callback();
        } else {
          callback(new Error("Correo requerido."));
        }
      }
    },
    onOrderFiscalPostalCodeValidator(rules, value, callback) {
      if (this.useCustomerData === "none") {
        callback();
      } else {
        if (value) {
          callback();
        } else {
          callback(new Error("Código Postal requerido."));
        }
      }
    },
    onSenderCustomerLocationValidation(rules, value, callback) {
      if (value && value.id) {
        callback();
      } else {
        callback(new Error("Ubicación de Remitente Requerido."));
      }
    },
    onReceiverCustomerLocationValidation(rules, value, callback) {
      if (value && value.id) {
        callback();
      } else {
        callback(new Error("Ubicación de Destinatario Requerido."));
      }
    },
    onReceiverSizeValidation(rules, value, callback) {
      this.viewModel.receiverSizeValidation(rules, value, callback);
    },
    onSenderSizeValidation(rules, value, callback) {
      this.viewModel.senderSizeValidation(rules, value, callback);
    },
    onOrderFiscalBillUsingTypeValidator(rules, value, callback) {
      this.viewModel.orderFiscalBillUsingTypeValidation(rules, value, callback);
    },
    onOrderFiscalPaymentMethodValidator(rules, value, callback) {
      this.viewModel.orderFiscalPaymentMethodValidation(rules, value, callback);
    }
  },
};
