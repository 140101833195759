import _ from 'lodash';
import BaseList from '../infraestructure/BaseList'
import TaxRegimeViewModel from '../../core/features/taxRegimes/TaxRegimeViewModel'


export default {
    extends: BaseList,
    data() {
        return {
            modulePermissions: 'TaxRegime',
            viewModel: null,
            isMoreFilterVisible: false,
            filtersSelected: {
                code: '',
                name: '',
                physicalPerson: '',
                moral: '',
                status: '',
            },
            modalImport: {
                isVisible: false,
                isLoading: false,
                title: 'IMPORTAR REGIMENES FISCALES',
                onUploadImport: 'on-upload-import-tax-regimes',
                onCancelImport: 'on-cancel-import-tax-regimes'
            }
        }
    },
    mounted() {
        this.onMountedEvent('on-submited-tax-regime', this.onSearch)
        this.onMountedEvent('on-upload-import-tax-regimes', this.onUploadImport);
        this.onMountedEvent('on-cancel-import-tax-regimes', this.onCancelImport);
    },
    beforeDestroy() {
        this.onBeforeDestroyEvent('on-submited-tax-regime', this.onSearch)
        this.onBeforeDestroyEvent('on-upload-import-tax-regimes', this.onUploadImport);
        this.onBeforeDestroyEvent('on-cancel-import-tax-regimes', this.onCancelImport);
    },
    created() {
        this.viewModel = new TaxRegimeViewModel(this)
        this.onActive()
    },

    methods: {
        onActive() {
            this.onSearch()
        },
        onSelectTaxRegime() { },
        onCreate() {
            this.item = this.viewModel.create({ isActive: true })
            this.modalConfig.isVisible = true
        },
        onMoreFilters() {
            this.isMoreFilterVisible = true
        },
        onSearchByName: _.debounce(function (text) {
            if (text) {
                this.addFilter('Name', text)
            } else {
                this.removeFilter('Name')
            }
            this.onSearch();
        }, 500),
        onSearchByCode: _.debounce(function (text) {
            if (text) {
                this.addFilter('Code', text)
            } else {
                this.removeFilter('Code')
            }
            this.onSearch();
        }, 500),
        searchMoralFilter(data) {
            if (data !== null) {
                this.addFilter('IsMoral', data)
            } else {
                this.removeFilter('IsMoral')
            }
            this.onSearch()
        },
        searchPhysicalPersonFilter(data) {
            if (data !== null) {
                this.addFilter('IsPhysicalPerson', data)
            } else {
                this.removeFilter('IsPhysicalPerson')
            }
            this.onSearch()
        },
        searchStatusFilter(data) {
            if (data !== null) {
                this.addFilter('IsActive', data)
            } else {
                this.removeFilter('IsActive')
            }
            this.onSearch()
        },
        onClearFilters() {
            this.viewModel.allClearFilters();
        },
    }
}