import BaseModel from "../../infraestructure/BaseModel"
import CustomerLocation from "../customerLocation/CustomerLocation"
import User from "../users/User"
import CustomerSubAccount from "../customerAccount/CustomerSubAccount"

export default class Customer extends BaseModel {
  constructor(options, state) {
    super(options, state)
  }

  toSend() {
    return {
      id: this.id,
      // code: this.code.trim(),
      description: this.description.trim(),
      fiscalCode: this.fiscalCode,
      fiscalName: this.fiscalName,
      fiscalPostalCodeId: (this.fiscalPostalCode) ? this.fiscalPostalCode.id : null,
      taxRegimeId: (this.taxRegime) ? this.taxRegime.id : null,
      discountPercentage: this.discountPercentage,
      contactName: this.contactName,
      contactPhone: this.contactPhone,
      contactEmail: this.contactEmail,
      placeId: (this.place) ? this.place.id : null,
      isActive: this.isActive,
      allowSubAccounts: this.allowSubAccounts,
      customerLocations: this.customerLocations.map((item) => item.toSend()), // this.mapCustomerLocations(this.customerLocations),
      customerSubAccounts: this.mapCustomerAccount(this.customerSubAccounts),
      masterAccount: (this.masterAccount !== null && this.masterAccount.isActive) ? this.mapCustomerMasterAccount(this.masterAccount) : null
    }
  }

  mapCustomerLocations(collection) {
    return collection.map((item) => {
      return {
        id: item.id,
        code: item.code,
        customerCode: item.customerCode,
        isCustomerCodeDocUsed: item.isCustomerCodeDocUsed,
        description: item.description,
        mainStreet: item.mainStreet,
        secondaryStreet: item.secondaryStreet,
        extNumber: item.extNumber,
        postalCodeId: (item.postalCode.id) ? item.postalCode.id: null,
        neighborhoodId: (item.neighborhood) ? item.neighborhood.id : null,
        cityId: (item.city) ? item.city.id : null,
        municipalityId: (item.municipality) ? item.municipality.id : null,
        districtId: (item.district) ? item.district.id : null,
        countryId: (item.country) ? item.country.id : null,
        isActive: item.isActive,
        updateState: item.updateState
      }
    })
  }

  mapCustomerMasterAccount(data) {
    return {
      id: data.id,
      userName: data.userName.trim(),
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
      passwordConfirmation: data.password,
      email: data.email.trim(),
      customerId: data.customerId,
      isActive: data.isActive
    }

  }

  mapCustomerAccount(collection) {
    return collection.map((item) => {
      return {
        id: item.id,
        isActive: item.isActive,
        updateState: item.updateState,
        accountName: item.user.accountName,
        user: item.user,
      }
    })
  }

  //#region CUSTOMER LOCATIONS

  getCustomerLocations() {
    return this.customerLocations.filter((item) => item.updateState != this.UPDATE_STATUS.REMOVED)
  }

  createCustomerLocation() {
    var item = new CustomerLocation({
      isActive: true,
      updateState: this.UPDATE_STATUS.ADDED
    }, 'create')
    return item
  }

  editCustomerLocation(data) {
    data.updateState = (data.id) ? this.UPDATE_STATUS.UPDATED : this.UPDATE_STATUS.ADDED
    return data
  }

  upsertCustomerLocation(index, data) {
    if (index != null) {
      var item = this.getCustomerLocations()[index]
      if (item) {
        var currentIndex = this.customerLocations.indexOf(item)
        if (currentIndex != -1) {
          this.customerLocations[currentIndex] = data
        }
      }
    } else {
      this.customerLocations.push(data)
    }
  }

  removeCustomerLocation(index) {
    var item = this.getCustomerLocations()[index]
    if (item) {
      var currentIndex = this.customerLocations.indexOf(item)
      if (currentIndex != -1) {
        this.customerLocations[currentIndex].updateState = this.UPDATE_STATUS.REMOVED
      } else {
        throw new Error('Ubicación del cliente no encontrada.')
      }
    } else {
      throw new Error('Ubicación del cliente no encontrada.')
    }
  }

  //#endregion

  //#region CUSTOMER SUB ACCOUNTS

  getCustomerSubAccounts() {
    return this.customerSubAccounts.filter((item) => item.updateState != this.UPDATE_STATUS.REMOVED)
  }

  createCustomerSubAccount() {
    var item = new User({}, 'create')
    return item
  }

  upsertCustomerSubAccount(index, data) {
    if (index != null) {
      var item = this.getCustomerSubAccounts()[index]
      if (item) {
        var currentIndex = this.customerSubAccounts.indexOf(item)
        if (currentIndex != -1) {
          this.customerSubAccounts[currentIndex] = data
        }
      }
    } else {
      this.customerSubAccounts.push(new CustomerSubAccount({
        user: data,
        isActive: true,
        updateState: this.UPDATE_STATUS.ADDED
      }))
    }
  }
  //#endregion
}